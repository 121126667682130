export var POST_ACTION_SUBSCRIBE = 'subscribe';
export var POST_ACTION_EDIT = 'edit';
export var POST_ACTION_SHARE = 'share';
export var POST_ACTION_PRINT = 'print';
export var POST_ACTION_PIN = 'pin';
export var POST_ACTION_TOGGLE_COMMENTS = 'toggle-comments';
export var POST_ACTION_REPORT = 'report';
export var POST_ACTION_DELETE = 'delete';
export var POST_ACTION_DUPLICATE = 'duplicate';
export var POST_ACTION_REVERT_TO_DRAFT = 'revert-to-draft';
export var POST_ACTION_REJECT_POST_IN_MODERATION = 'reject-post-in-moderation';
export var POST_ACTION_REVERT_POST_IN_MODERATION = 'revert-post-in-moderation';
export var POST_ACTION_TRANSLATE = 'translate';
export var POST_ACTION_CHANGE_LANGUAGE = 'change-language';
export var POST_ACTIONS = [
    POST_ACTION_SUBSCRIBE,
    POST_ACTION_EDIT,
    POST_ACTION_SHARE,
    POST_ACTION_PRINT,
    POST_ACTION_PIN,
    POST_ACTION_REVERT_TO_DRAFT,
    POST_ACTION_TOGGLE_COMMENTS,
    POST_ACTION_REPORT,
    POST_ACTION_DELETE,
    POST_ACTION_TRANSLATE,
    POST_ACTION_CHANGE_LANGUAGE,
];
