import React from 'react';
import { useErrorBoundary } from '@wix/yoshi-flow-editor';
import InvalidAppStatePage from '../components/invalid-app-state-page';

export function withErrorState(Component) {
  const ErrorState = InvalidAppStatePage;

  return (props) => {
    const { error, errorId } = useErrorBoundary();

    if (error) {
      return <ErrorState error={error} errorId={errorId} {...props} />;
    }

    return <Component {...props} />;
  };
}
