export var VIEW_MODE_EDITOR = 'editor';
export var VIEW_MODE_PREVIEW = 'preview';
export var VIEW_MODE_SITE = 'site';
export var VIEW_MODE_DASHBOARD = 'dashboard';
export var VIEW_MODE_VIEWER_SEO = 'viewer-seo';
export var VIEW_MODE_VIEWER = 'viewer';
export var VIEW_MODE_ONBOARDING = 'onboarding';
export var ALL_VIEW_MODES = [
    VIEW_MODE_EDITOR,
    VIEW_MODE_PREVIEW,
    VIEW_MODE_SITE,
    VIEW_MODE_DASHBOARD,
    VIEW_MODE_VIEWER_SEO,
    VIEW_MODE_VIEWER,
    VIEW_MODE_ONBOARDING,
];
