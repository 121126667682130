import { connect } from 'react-redux';

export default (mapRuntimeToProps) =>
  connect((state, ownProps) => {
    return mapRuntimeToProps(
      state.state,
      ownProps,
      state.actions,
      state.host,
      state.workerReady,
    );
  }, {});
