import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';

export const MY_POSTS_PAGE_BUNDLE_NAME = 'my-posts-page';

export const MY_POSTS_PAGE_TAB_PUBLICATIONS = 'publications';
export const MY_POSTS_PAGE_TAB_DRAFTS = 'drafts';
export const MY_POSTS_PAGE_TAB_SUBMISSIONS = 'submissions';

export const getPostStatusByMyPostsPageTab = (
  activeTab,
  isRenameInModerationToInReviewEnabled,
) => {
  const statuses = {
    [MY_POSTS_PAGE_TAB_PUBLICATIONS]: POST_STATUS.published,
    [MY_POSTS_PAGE_TAB_DRAFTS]: POST_STATUS.unpublished,
    [MY_POSTS_PAGE_TAB_SUBMISSIONS]: isRenameInModerationToInReviewEnabled
      ? POST_STATUS.in_review
      : POST_STATUS.in_moderation,
  };

  return statuses[activeTab];
};

export const MY_POSTS_PAGE_SIZE = 25;
