export var IS_TAG_LABEL_ENABLED = 'post-isTagLabelEnabled';
export var IS_TAG_LABEL_ENABLED_PATH = "style.booleans.".concat(IS_TAG_LABEL_ENABLED);
export var TAG_LAYOUT = 'tagLayout';
export var TAG_LAYOUT_PATH = "style.numbers.".concat(TAG_LAYOUT);
export var TAG_LAYOUT_MOBILE = 'tagLayoutMobile';
export var TAG_LAYOUT_MOBILE_PATH = "style.numbers.".concat(TAG_LAYOUT_MOBILE);
export var TAG_LAYOUTS = {
    button: 0,
    text: 1,
};
export var DEFAULT_TAG_LAYOUT = TAG_LAYOUTS.button;
export var isTagLayoutButton = function (tagLayout) {
    return tagLayout === TAG_LAYOUTS.button;
};
export var TAG_ALIGNMENTS = {
    left: 0,
    center: 1,
    right: 2,
};
export var TAG_ORDERS = {
    mostUsed: 0,
    alphabetical: 1,
};
export var BUTTON_TAG_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'tags-buttonTag-textFont',
        appSettingsPath: 'style.fonts.tags-buttonTag-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'tags-buttonTag-textColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.tags-buttonTag-textColor',
    },
    textColorHover: {
        wixParam: 'tags-buttonTag-textHoverColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.tags-buttonTag-textHoverColor',
    },
    backgroundColor: {
        wixParam: 'tags-buttonTag-backgroundColor',
        appSettingsPath: 'style.colors.tags-buttonTag-backgroundColor',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    backgroundColorHover: {
        wixParam: 'tags-buttonTag-backgroundHoverColor',
        appSettingsPath: 'style.colors.tags-buttonTag-backgroundHoverColor',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    borderColor: {
        wixParam: 'tags-buttonTag-borderColor',
        defaultColor: 'color-5',
        defaultOpacity: 0.2,
        appSettingsPath: 'style.colors.tags-buttonTag-borderColor',
    },
    borderColorHover: {
        wixParam: 'tags-buttonTag-borderHoverColor',
        defaultColor: 'color-5',
        defaultOpacity: 0.6,
        appSettingsPath: 'style.colors.tags-buttonTag-borderHoverColor',
    },
    borderWidth: {
        wixParam: 'tags-buttonTag-borderWidth',
        appSettingsPath: 'style.numbers.tags-buttonTag-borderWidth',
        defaultWidth: 1,
        minWidth: 0,
        maxWidth: 50,
    },
    cornerRadius: {
        wixParam: 'tags-buttonTag-cornerRadius',
        appSettingsPath: 'style.numbers.tags-buttonTag-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
};
export var BUTTON_TAG_DESIGN_PARAMS_MOBILE = {
    textFont: {
        wixParam: 'tags-mobile-buttonTag-textFont',
        appSettingsPath: 'style.fonts.tags-mobile-buttonTag-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'tags-mobile-buttonTag-textColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-textColor',
    },
    textColorHover: {
        wixParam: 'tags-mobile-buttonTag-textHoverColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-textHoverColor',
    },
    backgroundColor: {
        wixParam: 'tags-mobile-buttonTag-backgroundColor',
        defaultColor: 'color-1',
        defaultOpacity: 1,
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-backgroundColor',
    },
    backgroundColorHover: {
        wixParam: 'tags-mobile-buttonTag-backgroundHoverColor',
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-backgroundHoverColor',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    borderColor: {
        wixParam: 'tags-mobile-buttonTag-borderColor',
        defaultColor: 'color-5',
        defaultOpacity: 0.2,
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-borderColor',
    },
    borderColorHover: {
        wixParam: 'tags-mobile-buttonTag-borderHoverColor',
        defaultColor: 'color-5',
        defaultOpacity: 0.6,
        appSettingsPath: 'style.colors.tags-mobile-buttonTag-borderHoverColor',
    },
    borderWidth: {
        wixParam: 'tags-mobile-buttonTag-borderWidth',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-borderWidth',
        defaultWidth: 1,
        minWidth: 0,
        maxWidth: 50,
    },
    cornerRadius: {
        wixParam: 'tags-mobile-buttonTag-cornerRadius',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
};
export var TEXT_TAG_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'tags-textTag-textFont',
        appSettingsPath: 'style.fonts.tags-textTag-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'tags-textTag-textColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.fonts.tags-textTag-textColor',
    },
    textColorHover: {
        wixParam: 'tags-textTag-textHoverColor',
        defaultColor: 'color-8',
        appSettingsPath: 'style.fonts.tags-textTag-textHoverColor',
    },
};
export var TEXT_TAG_DESIGN_PARAMS_MOBILE = {
    textFont: {
        wixParam: 'tags-mobile-textTag-textFont',
        appSettingsPath: 'style.fonts.tags-mobile-textTag-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'tags-mobile-textTag-textColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.fonts.tags-mobile-textTag-textColor',
    },
    textColorHover: {
        wixParam: 'tags-mobile-textTag-textHoverColor',
        defaultColor: 'color-8',
        appSettingsPath: 'style.fonts.tags-mobile-textTag-textHoverColor',
    },
};
export var BUTTON_TAG_LAYOUT_PARAMS = {
    tagAlignment: {
        wixParam: 'tags-buttonTag-alignment',
        appSettingsPath: 'style.numbers.tags-buttonTag-alignment',
        defaultAlignment: TAG_ALIGNMENTS.left,
    },
    verticalPadding: {
        wixParam: 'tags-buttonTag-verticalPadding',
        appSettingsPath: 'style.numbers.tags-buttonTag-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'tags-buttonTag-horizontalPadding',
        appSettingsPath: 'style.numbers.tags-buttonTag-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    verticalSpacing: {
        wixParam: 'tags-buttonTag-verticalSpacing',
        appSettingsPath: 'style.numbers.tags-buttonTag-verticalSpacing',
        defaultSpacing: 8,
        minSpacing: 0,
        maxSpacing: 100,
    },
    horizontalSpacing: {
        wixParam: 'tags-buttonTag-horizontalSpacing',
        appSettingsPath: 'style.numbers.tags-buttonTag-horizontalSpacing',
        defaultSpacing: 8,
        minSpacing: 0,
        maxSpacing: 100,
    },
};
export var BUTTON_TAG_LAYOUT_PARAMS_MOBILE = {
    tagAlignment: {
        wixParam: 'tags-mobile-buttonTag-alignment',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-alignment',
        defaultAlignment: TAG_ALIGNMENTS.left,
    },
    verticalPadding: {
        wixParam: 'tags-mobile-buttonTag-verticalPadding',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'tags-mobile-buttonTag-horizontalPadding',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    verticalSpacing: {
        wixParam: 'tags-mobile-buttonTag-verticalSpacing',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-verticalSpacing',
        defaultSpacing: 8,
        minSpacing: 0,
        maxSpacing: 100,
    },
    horizontalSpacing: {
        wixParam: 'tags-mobile-buttonTag-horizontalSpacing',
        appSettingsPath: 'style.numbers.tags-mobile-buttonTag-horizontalSpacing',
        defaultSpacing: 8,
        minSpacing: 0,
        maxSpacing: 100,
    },
};
export var TEXT_TAG_LAYOUT_PARAMS = {
    tagAlignment: {
        wixParam: 'tags-textTag-alignment',
        appSettingsPath: 'style.numbers.tags-textTag-alignment',
        defaultAlignment: TAG_ALIGNMENTS.left,
    },
    verticalSpacing: {
        wixParam: 'tags-textTag-verticalSpacing',
        appSettingsPath: 'style.numbers.tags-textTag-verticalSpacing',
        defaultSpacing: 12,
        minSpacing: 0,
        maxSpacing: 100,
    },
    horizontalSpacing: {
        wixParam: 'tags-textTag-horizontalSpacing',
        appSettingsPath: 'style.numbers.tags-textTag-horizontalSpacing',
        defaultSpacing: 12,
        minSpacing: 0,
        maxSpacing: 100,
    },
};
export var TEXT_TAG_LAYOUT_PARAMS_MOBILE = {
    tagAlignment: {
        wixParam: 'tags-mobile-textTag-alignment',
        appSettingsPath: 'style.numbers.tags-mobile-textTag-alignment',
        defaultAlignment: TAG_ALIGNMENTS.left,
    },
    verticalSpacing: {
        wixParam: 'tags-mobile-textTag-verticalSpacing',
        appSettingsPath: 'style.numbers.tags-mobile-textTag-verticalSpacing',
        defaultSpacing: 12,
        minSpacing: 0,
        maxSpacing: 100,
    },
    horizontalSpacing: {
        wixParam: 'tags-mobile-textTag-horizontalSpacing',
        appSettingsPath: 'style.numbers.tags-mobile-textTag-horizontalSpacing',
        defaultSpacing: 12,
        minSpacing: 0,
        maxSpacing: 100,
    },
};
export var getTagLayoutParams = function (tagLayout, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    switch (tagLayout) {
        case TAG_LAYOUTS.button:
            return isMobile
                ? BUTTON_TAG_LAYOUT_PARAMS_MOBILE
                : BUTTON_TAG_LAYOUT_PARAMS;
        case TAG_LAYOUTS.text:
            return isMobile ? TEXT_TAG_LAYOUT_PARAMS_MOBILE : TEXT_TAG_LAYOUT_PARAMS;
        default:
            return;
    }
};
export var TAG_DISPLAY_PARAMS = {
    showPostCount: {
        wixParam: 'tags-showPostCount',
        appSettingsPath: 'style.booleans.tags-showPostCount',
        defaultValue: false,
    },
    orderTags: {
        wixParam: 'tags-orderTags',
        appSettingsPath: 'style.numbers.tags-orderTags',
        defaultValue: TAG_ORDERS.mostUsed,
    },
    numberOfTags: {
        wixParam: 'tags-numberOfTags',
        appSettingsPath: 'style.numbers.tags-numberOfTags',
        defaultValue: 75,
        minValue: 1,
        maxValue: 500,
    },
};
export var TAG_DISPLAY_PARAMS_MOBILE = {
    showPostCount: {
        wixParam: 'tags-mobile-showPostCount',
        appSettingsPath: 'style.booleans.tags-mobile-showPostCount',
        defaultValue: false,
    },
    orderTags: {
        wixParam: 'tags-mobile-orderTags',
        appSettingsPath: 'style.numbers.tags-mobile-orderTags',
        defaultValue: TAG_ORDERS.mostUsed,
    },
    numberOfTags: {
        wixParam: 'tags-mobile-numberOfTags',
        appSettingsPath: 'style.numbers.tags-mobile-numberOfTags',
        defaultValue: 75,
        minValue: 1,
        maxValue: 100,
    },
};
export var TAG_CLOUD_MOBILE_PARAMS = {
    isMobileDisplaySettingsEnabled: {
        wixParam: 'tags-mobile-isDisplaySettingsEnabled',
        appSettingsPath: 'style.booleans.tags-mobile-isDisplaySettingsEnabled',
        defaultValue: false,
    },
    isMobileDesignSettingsEnabled: {
        wixParam: 'tags-mobile-isDesignSettingsEnabled',
        appSettingsPath: 'style.booleans.tags-mobile-isDesignSettingsEnabled',
        defaultValue: false,
    },
    isMobileLayoutSettingsEnabled: {
        wixParam: 'tags-mobile-isLayoutSettingsEnabled',
        appSettingsPath: 'style.booleans.tags-mobile-isLayoutSettingsEnabled',
        defaultValue: false,
    },
};
