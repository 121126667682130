import { get } from 'lodash';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { MY_POSTS_WIDGET_ID } from '../../constants/widgets';

export const getCommunitiesContext = (state) => state.communitiesContext;

export const getApplicationConfigFromContext = (
  communitiesContext,
  appDefinitionId,
) => get(communitiesContext, appDefinitionId, {});

export const getApplicationConfig = (state, appDefinitionId) =>
  getApplicationConfigFromContext(
    getCommunitiesContext(state),
    appDefinitionId,
  );

export const getIsMemberAreaInstalled = (state) =>
  Boolean(getApplicationConfig(state, SANTA_MEMBERS_APP_ID).isInstalled);

export const getIsMyPostsInstalled = (state) =>
  getIsMemberAreaInstalled(state) &&
  Boolean(getApplicationConfig(state, MY_POSTS_WIDGET_ID).isInstalled);
