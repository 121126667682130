import PropTypes from 'prop-types';
import React from 'react';
import styles from './link-text.scss';

const LinkText = ({ text, postCount, a11yText }) => {
  if (postCount) {
    return (
      <>
        {text} <span aria-hidden={true}>{postCount}</span>{' '}
        <span className={styles.srOnly}>{a11yText}</span>
      </>
    );
  }

  return text;
};

LinkText.propTypes = {
  text: PropTypes.string,
  postCount: PropTypes.string,
  a11yText: PropTypes.string,
};

export default LinkText;
