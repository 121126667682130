import React from 'react';
import withTranslate from '../../hoc/with-translate';
import Tags from './tags';

const getTranslations = (t, tags) => ({
  tagsLabel: t('post-page.tags-label'),
  emptyState: t('empty-state.no-tags'),
  tags: (tags || []).reduce((acc, val) => {
    acc[val.id] = {
      a11yText: t('tag-link.a11y-post-count', { count: val.publicationCount }),
      postCount: t('tag-link.post-count', { count: val.publicationCount }),
    };
    return acc;
  }, {}),
});

const TagsWithTranslation = ({ t, ...props }) => {
  return <Tags {...props} translations={getTranslations(t, props.tags)} />;
};

export default withTranslate(TagsWithTranslation);
