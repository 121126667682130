import { chain } from 'lodash';
import {
  POST_ACTIONS,
  POST_ACTION_SHARE,
  POST_ACTION_SUBSCRIBE,
} from '@wix/communities-blog-client-common';

export const getPostActions = ({
  actions = POST_ACTIONS,
  post,
  canSee,
  enableShare = true,
  enableSubscribe = true,
}) =>
  chain(actions)
    .filter((action) => canSee(action, 'post', post))
    .without(
      enableShare || POST_ACTION_SHARE,
      enableSubscribe || POST_ACTION_SUBSCRIBE,
    )
    .value();
