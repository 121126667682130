import { get } from 'lodash';

export const getTopology = (state) => get(state, 'topology', {});
export const getBaseUrl = (state) => get(state, 'topology.baseUrl', '');
export const getSectionUrl = (state) => get(state, 'topology.sectionUrl', '');
export const getPostPageSectionUrl = (state) =>
  get(state, 'topology.postPageSectionUrl', '');
export const getPostPagePath = (state) =>
  get(state, 'topology.postPagePath', '');
export const isHomePage = (state) => get(state, 'topology.isHomePage', false);

export const getIsTemplate = (state) =>
  getSectionUrl(state).indexOf('//www.wix.com/') > -1;
