import { get } from 'lodash';
export var getAppConfig = function (state) { return state.appConfig || {}; };
export var getSectionUrl = function (state) { return getAppConfig(state).sectionUrl; };
export var getPostPageSectionUrl = function (state) {
    return getAppConfig(state).postPageSectionUrl;
};
export var getFeedSectionUrl = function (state) { return getAppConfig(state).feedSectionUrl; };
export var getCategoryPath = function (state) { return getAppConfig(state).categoryPath; };
export var getLogBiToConsole = function (state) { return getAppConfig(state).logBiToConsole; };
export var getBundleName = function (state) { return getAppConfig(state).bundleName; };
export var getIsDebugEnabled = function (state) { return getAppConfig(state).debugEnabled; };
export var getImageHost = function (state) { return getAppConfig(state).imageHost; };
export var getVideoHost = function (state) { return getAppConfig(state).videoHost; };
export var getInstanceId = function (state) { return getAppConfig(state).instanceId; };
export var getAmpBaseUrl = function (state) { return getAppConfig(state).ampBaseUrl; };
export var getIsLinkifyEnabled = function (state) {
    return get(getAppConfig(state), 'editorConfig.enableLinkify', true);
};
export var getLinguistFlagsStaticsUrl = function (state) {
    return getAppConfig(state).linguistFlagsStaticsUrl;
};
var isBundleName = function (state, name) { return getBundleName(state) === name; };
export var getIsAppBundle = function (state) { return isBundleName(state, 'blog'); };
export var getIsPostPageBundle = function (state) { return isBundleName(state, 'post-page'); };
