import React from 'react';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import AppLoaded from '../../../common/components/app-loaded';
import TagCloud from '../tag-cloud';
import styles from './app-root.scss';
import { flowRight } from 'lodash';

const AppRoot = () => {
  return (
    <ResponsiveListener>
      <div className={styles.baseStyles}>
        <TagCloud />
        <AppLoaded />
      </div>
    </ResponsiveListener>
  );
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
});

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
